import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexProps } from '@codedazur/react-components'
import { FlexInline } from '../flex-inline/FlexInline'
import { TextProps, Typography as Text } from '../typography/Typography'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import lightTheme from '../../themes/lightTheme'
import iconTextLink from '../../assets/icons/icon-text-link.svg'
import iconTextLinkHover from '../../assets/icons/icon-text-link-hover.svg'
import iconTextExternalLink from '../../assets/icons/icon-text-link-external.svg'
import iconTextExternalLinkHover from '../../assets/icons/icon-text-link-external-hover.svg'
import iconTextLinkCross from '../../assets/icons/icon-text-link-cross.svg'
import iconTextLinkCrossHover from '../../assets/icons/icon-text-link-cross-hover.svg'
import { Link } from 'gatsby'
import isExternalLink from '../../utils/isExternalLink'

export enum IconType {
	arrow = 'arrow',
	cross = 'cross',
}

const LinkStyle = `
font-family: ${FontFamily.bold};
font-size: ${rem(16)};
line-height: ${rem(32)};
color: ${lightTheme.accents.navyBlue};
text-decoration: none;
`

export const AnchorStyled = styled.a`
	${LinkStyle}
`

export const LinkStyled = styled(Link)`
	${LinkStyle}
`

const getIconSvg = (
	iconType: IconType = IconType.arrow,
	isExternal: boolean,
	isHover: boolean,
) => {
	if (iconType === IconType.cross) {
		return isHover ? iconTextLinkCrossHover : iconTextLinkCross
	}

	if (iconType === IconType.arrow && isExternal) {
		return isHover ? iconTextExternalLinkHover : iconTextExternalLink
	}

	return isHover ? iconTextLinkHover : iconTextLink
}

export interface IconProps {
	externalUrl: boolean
	show?: 'show' | 'showOnHoverOnly' | 'noIcon'
	iconType?: IconType
}

export const Icon = styled.div<IconProps>`
	display: inline-block;
	vertical-align: top;
	height: 32px;
	width: 32px;
	margin-left: 8px;

	${({ show }) => {
		if (show === 'showOnHoverOnly') {
			return 'opacity: 0'
		} else {
			return 'opacity: 1'
		}
	}};

	${LinkStyled}:hover &,
	${AnchorStyled}:hover & {
		opacity: 1;
	}

	background: ${({ externalUrl, iconType }) =>
		`url(${getIconSvg(iconType, externalUrl, false)})`};
`

interface TextLinkStyledProps extends FlexProps {
	externalUrl: boolean
	iconType?: IconType
}

export const TextInlineStyled = styled(FlexInline)<TextLinkStyledProps>`
	display: inline;
	&:hover {
		cursor: pointer;
	}
	&:hover ${Icon} {
		background: ${({ externalUrl, iconType }) =>
			`url(${getIconSvg(iconType, externalUrl, true)})`};
	}
`

export interface TextWithIconProps {
	text?: TextProps
	icon?: 'show' | 'showOnHoverOnly' | 'noIcon'
	iconType?: IconType
	external?: boolean
}

const TextWithIcon: FC<PropsWithChildren<TextWithIconProps>> = ({
	icon = 'show',
	iconType = IconType.arrow,
	text = {},
	external = false,
	children,
}) => (
	<TextInlineStyled externalUrl={external} iconType={iconType}>
		<Text
			{...text}
			css={`
				display: inline;
			`}
		>
			{children}
		</Text>
		{icon !== 'noIcon' && (
			<Icon externalUrl={external} iconType={iconType} show={icon} />
		)}
	</TextInlineStyled>
)

export interface TextLinkProps {
	text?: TextProps
	href?: string
	icon?: 'show' | 'showOnHoverOnly' | 'noIcon'
	iconType?: IconType
	onClick?: React.MouseEventHandler
	className?: string
}

const TextLink: FC<PropsWithChildren<TextLinkProps>> = ({
	href = '#',
	icon = 'show',
	iconType = IconType.arrow,
	text = {},
	onClick = undefined,
	className = '',
	children,
}) => {
	const [isExternal, setIsExternal] = useState(false)
	useEffect(() => {
		setIsExternal(isExternalLink(href))
	}, [href])

	if (href === '#') {
		// href is ignored
		return (
			<span onClick={onClick} className={className}>
				<TextWithIcon
					icon={icon}
					iconType={iconType}
					text={text}
					external={false}
				>
					{children}
				</TextWithIcon>
			</span>
		)
	} else {
		// onClick is ignored
		return isExternal ? (
			<AnchorStyled
				href={href}
				target="_blank"
				rel="noopener noreferrer"
				className={className}
			>
				<TextWithIcon
					iconType={iconType}
					icon={icon}
					text={text}
					external={isExternal}
				>
					{children}
				</TextWithIcon>
			</AnchorStyled>
		) : (
			<LinkStyled to={href} className={className}>
				<TextWithIcon
					iconType={iconType}
					icon={icon}
					text={text}
					external={isExternal}
				>
					{children}
				</TextWithIcon>
			</LinkStyled>
		)
	}
}

export default TextLink
