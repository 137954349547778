import { createContext } from 'react'

interface LocaleContext {
	locale: string
	marketSlug: string
	changeLocale: (newLocale: string, internal: boolean) => void
}

export const LocaleContext = createContext<LocaleContext>({
	locale: '',
	marketSlug: '',
	changeLocale: () => {},
})
