/* This approach
  - automatically resolve the hostname for
    relative paths and fragments;
  - also  works with protocol-relative URLs
  */

const isBrowser = typeof window !== 'undefined'

const hostForDomain = process.env.GATSBY_SITE_URL || 'www.mscpressarea.com'

const normalizeDomain = (domain: string) =>
	domain.replace('https://', '').replace('http://', '').replace('//', '')

const isExternalLink = (link: string) => {
	if (!isBrowser) return false

	const host = normalizeDomain(hostForDomain)

	if (link.startsWith('#') || link.startsWith('/')) {
		return false
	}

	const url = '//' + normalizeDomain(link)

	const tmp = document.createElement('a')
	tmp.href = url

	return tmp.host !== host
}

export default isExternalLink
