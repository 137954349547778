import { PageProps } from 'gatsby'
import React, { FC, useContext } from 'react'
import { LocaleContext } from '../../context/LocaleContext'
import { getCanonicalUrl } from '../../utils/getCanonicalUrl'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { SeoMetaTags as SeoMetaTagsDataProps } from '../../../common/types/pageContext'
import { PageContext } from '../../context/PageContext'

interface SeoMetaTagsProps {
	location: PageProps['location']
	seoMetaTags?: SeoMetaTagsDataProps
}

// TODO: Add global and page specific seo meta tags
const SeoMetaTags: FC<SeoMetaTagsProps> = ({ location, seoMetaTags }) => {
	const { marketSlug } = useContext(LocaleContext)

	const canonicalUrl = getCanonicalUrl(location, marketSlug)
	const { locale } = useContext(PageContext)

	return (
		<HelmetDatoCms seo={seoMetaTags} htmlAttributes={{ lang: locale }}>
			<link rel="canonical" href={canonicalUrl} />
		</HelmetDatoCms>
	)
}

export default SeoMetaTags
