import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import lightTheme from '../../themes/lightTheme'
import GlobalReset from './global-styles/GlobalReset'
import GlobalHeight from './global-styles/GlobalHeight'
import GlobalBackground from './global-styles/GlobalBackground'
import GlobalFonts from './global-styles/GlobalFonts'
import GlobalText from './global-styles/GlobalText'
import { PageContext } from '../../context/PageContext'
import CookieConsent from '../cookie-consent/CookieConsent'
import { useDatoLocale } from '../../hooks/useDatoLocale'
import { LocaleContext } from '../../context/LocaleContext'
import {
	DictionaryProvider,
	getDictionaryEntryByKey,
} from '../../context/DictionaryContext'
import { PageContextProps } from '../../../common/types/pageContext'
import { useHandleRedirects } from '../../hooks/useHandleRedirects'
import { PageProps } from 'gatsby'
import SeoMetaTags from '../seo-meta-tags/SeoMetaTags'
import { ConsentProvider } from '../../context/ConsentContext'
import { GlobalStyle } from '@codedazur/react-components'
import { DictionaryEntryProps } from '../../../common/types/dictionary'

type DictionaryData = {
	edges: {
		node: {
			key: string
			value: string
		}
	}[]
}

interface AppProps extends Pick<PageProps, 'location'> {
	children: ReactNode
	rootSelector?: string
	pageContext: PageContextProps
	data: {
		allDatoCmsMscDictionary: DictionaryData
	}
}

const App: FC<AppProps> = ({
	rootSelector = '#___gatsby, #gatsby-focus-wrapper',
	pageContext,
	location,
	children,
	data,
}) => {
	const { allDatoCmsMscDictionary } = data
	const dictionary = useMemo(
		() => parseDictionaryData(allDatoCmsMscDictionary),
		[allDatoCmsMscDictionary],
	)
	const { locales, localeMapSlug, slugMapLocale } = pageContext
	const {
		locale,
		changeLocale,
		marketSlug,
		internalLocaleChange,
		setInternalLocaleChange,
	} = useDatoLocale(locales, localeMapSlug)

	useHandleRedirects(
		marketSlug,
		changeLocale,
		internalLocaleChange,
		setInternalLocaleChange,
		location,
		slugMapLocale,
	)

	const getDictionaryEntry = useCallback(
		(key: string, defaultValue?: string): string =>
			getDictionaryEntryByKey(dictionary || [], key, defaultValue),
		[dictionary],
	)

	return (
		<ConsentProvider>
			<LocaleContext.Provider value={{ locale, changeLocale, marketSlug }}>
				<PageContext.Provider value={pageContext}>
					<DictionaryProvider
						value={{ entries: dictionary || [], get: getDictionaryEntry }}
					>
						<ThemeProvider theme={lightTheme}>
							<SeoMetaTags
								seoMetaTags={pageContext.seoMetaTags}
								location={location}
							/>
							<CookieConsent />
							<GlobalStyle rootSelector={rootSelector} />
							<GlobalReset />
							<GlobalHeight rootSelector={rootSelector} />
							<GlobalBackground />
							<GlobalFonts />
							<GlobalText />
							{children}
						</ThemeProvider>
					</DictionaryProvider>
				</PageContext.Provider>
			</LocaleContext.Provider>
		</ConsentProvider>
	)
}

/**
 * Parse dictionary data to dictionary entries
 * @param data
 */
const parseDictionaryData = (data: DictionaryData): DictionaryEntryProps[] => {
	const edges = data.edges
	return edges.map((edge) => {
		return edge.node
	})
}

export default App
