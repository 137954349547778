exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-image-bank-tsx": () => import("./../../../src/templates/image-bank.tsx" /* webpackChunkName: "component---src-templates-image-bank-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/news-detail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-news-overview-tsx": () => import("./../../../src/templates/news-overview.tsx" /* webpackChunkName: "component---src-templates-news-overview-tsx" */),
  "component---src-templates-overview-page-tsx": () => import("./../../../src/templates/overview-page.tsx" /* webpackChunkName: "component---src-templates-overview-page-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-simple-page-tsx": () => import("./../../../src/templates/simple-page.tsx" /* webpackChunkName: "component---src-templates-simple-page-tsx" */),
  "component---src-templates-text-page-tsx": () => import("./../../../src/templates/text-page.tsx" /* webpackChunkName: "component---src-templates-text-page-tsx" */)
}

