import React, {
	ChangeEvent,
	ReactNode,
	useEffect,
	useId,
	useState,
} from 'react'
import styled from 'styled-components'
import useDictionary from '../../hooks/useDictionary'
import Container from '../container/Container'
import Spacing from '../spacing/Spacing'
import ContainedGrid, { GridItem } from '../contained-grid/ContainedGrid'
import TextLink, { IconType } from '../text-link/TextLink'
import {
	ConsentCategory,
	ConsentValue,
	DEFAULT_CONSENT,
} from '../../../common/consent/consent'
import useConsent from '../../hooks/useConsent'
import { Typography } from '../typography/Typography'

const CookieConsent = () => {
	const { visible, hide, stored, update, acceptAll, rejectAll } = useConsent()
	const dictionary = useDictionary()

	const [selected, setSelected] = useState<
		Record<ConsentCategory, ConsentValue>
	>(() => stored ?? DEFAULT_CONSENT)

	useEffect(() => {
		if (stored) {
			setSelected(stored)
		}
	}, [stored])

	const updateSelected = (category: ConsentCategory, value: boolean) => {
		setSelected((prev) => ({
			...prev,
			[category]: value ? ConsentValue.GRANTED : ConsentValue.DENIED,
		}))
	}

	const handleAcceptAll = () => {
		acceptAll()
		hide()
	}

	const handleRejectAll = () => {
		rejectAll()
		hide()
	}

	const handleAllowSelected = () => {
		update(selected)
		hide()
	}

	if (!visible) return null

	return (
		<CookieWrapper>
			<Container>
				<Spacing height={32} />
				<ContainedGrid hasOffset>
					<GridItem span={[10, 10]}>
						<div>
							<Typography variant="paragraph">
								{dictionary.get(
									'cookie-consent',
									'This Website uses Google Analytic cookies. These cookies help MSC to improve its website, by analyzing the usage. By continuing to use this Website the user consents to the use of these cookies.',
								)}
							</Typography>
						</div>
					</GridItem>
				</ContainedGrid>
				<Spacing height={16} />
				<ContainedGrid hasOffset>
					<GridItem span={[10, 10]}>
						<CheckboxList>
							<CheckboxListItem>
								<Checkbox
									label={dictionary.get(
										'cookie-consent.category_essential',
										'Essential',
									)}
									checked
									disabled
								/>
							</CheckboxListItem>
							<CheckboxListItem>
								<Checkbox
									label={dictionary.get(
										'cookie-consent.category_analytics',
										'Analytics',
									)}
									checked={
										selected[ConsentCategory.analytics] === ConsentValue.GRANTED
									}
									onChange={({ target }) =>
										updateSelected(ConsentCategory.analytics, target.checked)
									}
								/>
							</CheckboxListItem>
							<CheckboxListItem>
								<Checkbox
									label={dictionary.get(
										'cookie-consent.category_preference',
										'Preference',
									)}
									checked={
										selected[ConsentCategory.functionality] ===
										ConsentValue.GRANTED
									}
									onChange={({ target }) =>
										updateSelected(
											ConsentCategory.functionality,
											target.checked,
										)
									}
								/>
							</CheckboxListItem>
						</CheckboxList>
						<Spacing height={32} />
						<ConsentButton iconType={IconType.arrow} onClick={handleAcceptAll}>
							{dictionary.get('cookie-consent.accept_all', 'Accept all')}
						</ConsentButton>
						<ConsentButton iconType={IconType.cross} onClick={handleRejectAll}>
							{dictionary.get('cookie-consent.reject_all', 'Reject all')}
						</ConsentButton>
						<ConsentButton
							iconType={IconType.arrow}
							onClick={handleAllowSelected}
						>
							{dictionary.get(
								'cookie-consent.allow_selected',
								'Allow selected',
							)}
						</ConsentButton>
					</GridItem>
				</ContainedGrid>
				<Spacing height={32} />
			</Container>
		</CookieWrapper>
	)
}

interface CheckboxProps {
	label: string
	checked?: boolean
	disabled?: boolean
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

interface ConsentButtonProps {
	children: ReactNode
	iconType: IconType
	onClick: () => void
}

const ConsentButton = ({ children, iconType, onClick }: ConsentButtonProps) => {
	return (
		<StyledButton onClick={onClick}>
			<TextLink
				text={{
					variant: 'paragraph',
					fontFamily: 'bold',
					accent: 'navyBlue',
				}}
				iconType={iconType}
			>
				{children}
			</TextLink>
		</StyledButton>
	)
}

const Checkbox = ({ label, checked, disabled, onChange }: CheckboxProps) => {
	const id = useId()

	return (
		<Typography
			variant="label"
			css={`
				align-items: center;
				display: flex;
			`}
		>
			<input
				id={id}
				disabled={disabled}
				defaultChecked={checked}
				type="checkbox"
				onChange={onChange}
				css={`
					appearance: none;
					border-radius: 2px;
					border: 2px solid #00123d;
					color: currentColor;
					cursor: pointer;
					display: grid;
					font: inherit;
					height: 14px;
					margin-right: 4px;
					place-content: center;
					width: 14px;

					&:before {
						background: #00123d;
						content: '';
						height: 8px;
						opacity: 0;
						transform: scale(0.8);
						transition: 120ms transform ease-in-out;
						width: 8px;
					}

					&:checked:before {
						opacity: 1;
						transform: scale(1);
					}

					&:disabled {
						cursor: not-allowed;
						opacity: 0.4;
					}
				`}
			/>
			<label htmlFor={id}>{label}</label>
		</Typography>
	)
}

const StyledButton = styled.button`
	appearance: none;
	border: none;
	background: none;
	padding: 0;
	margin: 0 16px 0 0;
`

const CookieWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	background: #fff;
	border-top: 1px solid rgba(223, 224, 226, 1);
`

const CheckboxList = styled.ul``

const CheckboxListItem = styled.li`
	display: inline-block;
	margin: 0 16px 0 0;
`

export default CookieConsent
