import { breakpoints } from './breakpoints'

export const createMediaQuery = (
	minWidth: number,
	type = 'media',
	maxWidth: number | undefined = undefined,
): string =>
	`@${type} (min-width: ${minWidth}px)${
		maxWidth !== undefined ? ` and (max-width: ${maxWidth}px)` : ''
	}`

export const MediaQueries = {
	mobile: createMediaQuery(breakpoints.mobile),
	tablet: createMediaQuery(breakpoints.tablet),
	desktop: createMediaQuery(breakpoints.desktop),
	desktopBig: createMediaQuery(breakpoints.desktopBig),
	desktopBigger: createMediaQuery(breakpoints.desktopBigger),
}
