import { createContext } from 'react'
import { PageContextProps as PageContextProps } from '../../common/types/pageContext'

export const PageContext = createContext<PageContextProps>({
	marketPickerData: {},
	localeMapSlug: {},
	slugMapLocale: {},
	navigation: [],
	marketSlug: '',
	socialUrls: {},
	locale: '',
	locales: [],
})
