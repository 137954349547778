import { PageProps } from 'gatsby'

/**
 * Get conanical url for given pathname.
 * The market slug will be added to the conanical url if it's not present.
 * This happens when you are on the international market without the en_INT market slug
 *
 * @export
 * @param {WindowLocation<unknown>} location
 * @param {string} marketSlug
 * @returns {string}
 */
export function getCanonicalUrl(
	location: PageProps['location'],
	marketSlug: string,
): string {
	const baseUrl = process.env.GATSBY_SITE_URL || location.origin
	const isTrailingSlash = location.pathname.endsWith('/')
	let path = isTrailingSlash ? location.pathname : `${location.pathname}/`

	if (!path.includes(marketSlug)) {
		path = `/${marketSlug}${path}`
	}

	let url = new URL(`${path}${location.search}`, baseUrl)

	return url.href
}
