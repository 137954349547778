import Cookies from 'js-cookie'
import { useContext } from 'react'
import { ConsentCategory, ConsentValue } from '../../common/consent/consent'
import { ConsentContext } from '../context/ConsentContext'
import { COOKIE_CATEGORIES } from '../../common/consent/cookies'

const useConsent = () => {
	const { stored, setStored, visible, setVisible } = useContext(ConsentContext)

	const show = () => setVisible(true)

	const hide = () => setVisible(false)

	const update = (data: Partial<Record<ConsentCategory, ConsentValue>>) => {
		const newValue = { ...stored, ...data }

		// Update locally stored value
		setStored(newValue)

		// Update the datalayer with the new consent
		window.gtag?.('consent', 'update', newValue)

		// Remove any cookies that no longer have consent
		const grantedCategories = Object.entries(newValue)
			.filter(([, value]) => value === ConsentValue.GRANTED)
			.map(([name]) => name)
		Object.entries(COOKIE_CATEGORIES).forEach(([name, category]) => {
			if (!grantedCategories.includes(category)) {
				Cookies.remove(name, {
					domain: process.env.GATSBY_COOKIE_DOMAIN,
				})
			}
		})
	}

	const acceptAll = () => {
		update({
			[ConsentCategory.analytics]: ConsentValue.GRANTED,
			[ConsentCategory.functionality]: ConsentValue.GRANTED,
		})
	}

	const rejectAll = () => {
		update({
			[ConsentCategory.analytics]: ConsentValue.DENIED,
			[ConsentCategory.functionality]: ConsentValue.DENIED,
		})
	}

	return {
		visible,
		show,
		hide,
		stored,
		update,
		acceptAll,
		rejectAll,
	}
}

export default useConsent
