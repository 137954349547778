import React, { FC } from 'react'
import { HTMLAttributes } from 'react'
import styled, { css, DefaultTheme } from 'styled-components'
import { TextVariantsKeys } from '../../types/tokens/typography'
import { FontFamily } from '../../tokens/typography'
import { typography } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import { MediaQueries } from '../../tokens/mediaQueries'

interface TextBaseStyledProps extends HTMLAttributes<HTMLSpanElement> {
	noWrap?: boolean
}

export const TextBaseStyled = styled('span')<TextBaseStyledProps>`
	color: inherit;
	font-family: inherit;
	font-style: inherit;
	font-weight: inherit;
	font-size: ${rem(16)};
	line-height: ${rem(32)};

	${(props) =>
		props.noWrap &&
		css`
			max-width: 100%;
			display: inline-block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		`}
`

const TextStyled = styled(TextBaseStyled)<TextStyledProps>(
	(props) => css`
		font-family: ${typography[props.variant].mobile.fontFamily};
		font-size: ${rem(typography[props.variant].mobile.fontSize)};
		line-height: ${rem(typography[props.variant].mobile.lineHeight)};
		color: ${props.theme.accents[props.accent]};

		${MediaQueries.desktop} {
			font-family: ${typography[props.variant].desktop.fontFamily};
			font-size: ${rem(typography[props.variant].desktop.fontSize)};
			line-height: ${rem(typography[props.variant].desktop.lineHeight)};
		}

		font-family: ${props.fontFamily && FontFamily[props.fontFamily]};
		font-size: ${props.fontSize && rem(props.fontSize)};
		line-height: ${props.lineHeight && rem(props.lineHeight)};

		/* https://github.com/styled-components/styled-components/issues/1587 😬 */
		${MediaQueries.desktop} {
			font-family: ${props.fontFamily && FontFamily[props.fontFamily]};
			font-size: ${props.fontSize && rem(props.fontSize)};
			line-height: ${props.lineHeight && rem(props.lineHeight)};
		}

		strong {
			font-weight: bold;
		}
		em {
			font-style: italic;
		}
	`,
)

export interface TextProps extends TextBaseStyledProps {
	className?: string
	asProp?: string
	variant?: TextVariantsKeys
	accent?: keyof DefaultTheme['accents']
	fontFamily?: keyof typeof FontFamily
	lineHeight?: number
	fontSize?: number
}

interface TextStyledProps extends Required<TextProps> {}

export const Typography: FC<TextProps> = ({
	variant = 'sectionHeading',
	accent = 'mutedNavy',
	className = '',
	asProp,
	...props
}) => {
	const asElement = asProp
		? asProp
		: (typography[variant].desktop.element as string as any)

	return (
		<TextStyled
			className={className}
			{...props}
			variant={variant}
			accent={accent}
			as={asElement}
		/>
	)
}
