import styled, { css } from 'styled-components'
import { Grid as CDAGrid } from '@codedazur/react-components'
import lightTheme from '../../themes/lightTheme'
import React, { FC, PropsWithChildren } from 'react'
import Container, { ContainerProps } from '../container/Container'
import { spreadBreakpointValues } from '../../tokens/breakpoints'
import { MediaQueries } from '../../tokens/mediaQueries'

interface GridProps {
	rowGap?: [string, string] | string
	columns?: number
	disableRowGap?: boolean
}

export const Grid: FC<PropsWithChildren<GridProps>> = ({
	disableRowGap = false,
	rowGap = '20px',
	columns = 12,
	children,
}) => {
	const gridRowGap = spreadBreakpointValues(rowGap)

	return (
		<CDAGrid
			columns={columns}
			gap="20px"
			css={`
				row-gap: ${gridRowGap[0]};
				${disableRowGap && `row-gap: unset;`}

				${MediaQueries.desktop} {
					row-gap: ${gridRowGap[1]};
					/* https://github.com/styled-components/styled-components/issues/1587 */
					${disableRowGap && `row-gap: unset;`}
				}
			`}
		>
			{children}
		</CDAGrid>
	)
}

export interface ContainedGrid extends ContainerProps, GridProps {
	className?: string
}

const ContainedGrid: FC<PropsWithChildren<ContainedGrid>> = ({
	rowGap = '20px',
	hasOffset = false,
	withPageWrapper = false,
	pageWrapperBgColor = lightTheme.accents.lightGray20,
	disableRowGap = false,
	className = '',
	children,
}) => {
	return (
		<Container
			className={className}
			hasOffset={hasOffset}
			withPageWrapper={withPageWrapper}
			pageWrapperBgColor={pageWrapperBgColor}
		>
			<Grid rowGap={rowGap} disableRowGap={disableRowGap}>
				{children}
			</Grid>
		</Container>
	)
}

export default ContainedGrid

export const GridItemBase = styled.div<{ span: number[] }>(
	({ span }) => css`
		grid-column-start: span ${span[0]};
		height: 100%;
		${span[0] === 0 && `display: none;`}

		${MediaQueries.tablet} {
			grid-column-start: span ${span[1]};
			display: block;
			${span[1] === 0 && `display: none;`}
		}

		${MediaQueries.desktop} {
			grid-column-start: span ${span[2]};
			display: block;
			${span[2] === 0 && `display: none;`}
		}

		${MediaQueries.desktopBig} {
			grid-column-start: span ${span[3]};
			display: block;
			${span[3] === 0 && `display: none;`}
		}

		${MediaQueries.desktopBigger} {
			grid-column-start: span ${span[4]};
			display: block;
			${span[4] === 0 && `display: none;`}
		}
	`,
)

export interface GridItemProps {
	span?: number[] | number
	className?: string
}

export const GridItem: FC<PropsWithChildren<GridItemProps>> = ({
	span = 3,
	className = '',
	children,
}) => {
	const spanAcrossAllBreakpoints = spreadBreakpointValues(span)

	return (
		<GridItemBase className={className} span={spanAcrossAllBreakpoints}>
			{children}
		</GridItemBase>
	)
}
