export const breakpoints = {
	mobile: 0,
	tablet: 539,
	desktop: 959,
	desktopBig: 1023,
	desktopBigger: 1279,
}

export type Breakpoint = keyof typeof breakpoints

export const getBreakpointForWidth = (width: number): Breakpoint => {
	if (width < breakpoints['tablet']) return 'mobile'
	else if (width < breakpoints['desktop']) return 'tablet'
	else if (width < breakpoints['desktopBig']) return 'desktop'
	else return 'desktopBig'
}

/**
 * [12,12,12,12,12] --> [12,12,12,12,12]
 * [12,6] --> [12,12,6,6,6]
 * 12 --> [12,12,12,12,12]
 */
export const spreadBreakpointValues = <T>(values: T | T[]) => {
	if (Array.isArray(values)) {
		if (
			values.length !== 2 &&
			values.length != Object.keys(breakpoints).length
		) {
			throw new Error('You must define values for all possible breakpoints')
		}

		if (values.length === 2) {
			const nonDesktop = values[0]
			const desktop = values[1]

			return [nonDesktop, nonDesktop, desktop, desktop, desktop]
		} else {
			return values
		}
	} else {
		return [values, values, values, values, values]
	}
}

/* @TODO export fixed array size type for breakpoint values using tuples
https://stackoverflow.com/questions/41139763/how-to-declare-a-fixed-length-array-in-typescript */
