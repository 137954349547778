import {
	TypographyPerBreakpoint,
	TextTypography,
} from '../types/tokens/typography'

export const FontFamily = {
	// @TODO adjust sans-serif in terms of font-weight
	light: 'open-sans-light, sans-serif',
	regular: 'open-sans-regular, sans-serif',
	semiBold: 'open-sans-semi-bold, sans-serif',
	bold: 'open-sans-bold, sans-serif',
}

const paragraph: TypographyPerBreakpoint = {
	desktop: {
		fontFamily: FontFamily.regular,
		element: 'p',
		fontSize: 16,
		lineHeight: 32,
	},
	mobile: {
		fontFamily: FontFamily.regular,
		element: 'p',
		fontSize: 16,
		lineHeight: 28,
	},
}

const subHeading: TypographyPerBreakpoint = {
	desktop: {
		fontFamily: FontFamily.regular,
		element: 'h3',
		fontSize: 20,
		lineHeight: 32,
	},
	mobile: {
		fontFamily: FontFamily.regular,
		element: 'h3',
		fontSize: 18,
		lineHeight: 28,
	},
}

const label: TypographyPerBreakpoint = {
	desktop: {
		fontFamily: FontFamily.semiBold,
		element: 'span',
		fontSize: 12,
		lineHeight: 24,
	},
	mobile: {
		fontFamily: FontFamily.semiBold,
		element: 'span',
		fontSize: 12,
		lineHeight: 24,
	},
}

export const typography: TextTypography = {
	pageHeading: {
		desktop: {
			fontFamily: FontFamily.light,
			element: 'h1',
			fontSize: 54,
			lineHeight: 72,
		},
		mobile: {
			fontFamily: FontFamily.light,
			element: 'h1',
			fontSize: 46,
			lineHeight: 60,
		},
	},
	sectionHeading: {
		desktop: {
			fontFamily: FontFamily.regular,
			element: 'h3',
			fontSize: 28,
			lineHeight: 40,
		},
		mobile: {
			fontFamily: FontFamily.regular,
			element: 'h3',
			fontSize: 28,
			lineHeight: 36,
		},
	},
	sectionHeadingBold: {
		desktop: {
			fontFamily: FontFamily.semiBold,
			element: 'h2',
			fontSize: 36,
			lineHeight: 48,
		},
		mobile: {
			fontFamily: FontFamily.semiBold,
			element: 'h2',
			fontSize: 32,
			lineHeight: 44,
		},
	},
	subHeading,
	subHeadingBold: {
		desktop: {
			...subHeading.desktop,
			fontFamily: FontFamily.semiBold,
		},
		mobile: {
			...subHeading.mobile,
			fontFamily: FontFamily.semiBold,
		},
	},
	paragraph,
	paragraphBold: {
		desktop: {
			...paragraph.desktop,
			fontFamily: FontFamily.semiBold,
		},
		mobile: {
			...paragraph.mobile,
			fontFamily: FontFamily.semiBold,
		},
	},
	label,
	footnote: {
		desktop: {
			...label.desktop,
			fontFamily: FontFamily.regular,
		},
		mobile: {
			...label.mobile,
			fontFamily: FontFamily.regular,
		},
	},
	date: {
		desktop: {
			fontFamily: FontFamily.regular,
			element: 'span',
			fontSize: 16,
			lineHeight: 36,
		},
		mobile: {
			fontFamily: FontFamily.regular,
			element: 'span',
			fontSize: 14,
			lineHeight: 28,
		},
	},
}
