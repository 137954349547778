import { theme as cdaTheme } from './codeazurReactComponentsTheme'
import { DefaultTheme } from 'styled-components'

const cdaRequirements = {
	border: cdaTheme.border,
	colors: cdaTheme.colors,
}

const lightTheme: DefaultTheme = {
	...cdaRequirements,

	breakpoints: cdaTheme.breakpoints,

	accents: {
		link: '#2557CF',
		mutedNavy: '#3D4B6D',
		navyBlue: '#00123D',
		brandColor: '#00123D',
		lightGray20: 'rgba(223, 224, 226, 0.2)', // for backgrounds
		lightGray60: 'rgba(223, 224, 226, 0.6)', // for dividers, icons
		background: '#ffffff',
		white: '#ffffff',
	},

	text: {
		...cdaTheme.text,
		body: {
			...cdaTheme.text.body,
			fontFamily: 'open-sans-regular, sans-serif',
			color: '#3D4B6D',
		},
	},
}

export default lightTheme
