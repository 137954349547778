import 'styled-components'
import { Breakpoints, Theme } from '@codedazur/react-components'
import Color from 'color'

/* Theme props are requirement to defaultTheme so
  @codedazur/react-components won't crash  */
export const theme: Theme = {
	breakpoints: new Breakpoints({
		small: 480,
		medium: 768,
		large: 1024,
	}),
	border: {
		radius: '0.25rem',
		width: '1px',
		style: 'solid',
	},
	colors: {
		background: new Color(0xf0f0f0),
		foreground: new Color(0x404040),
		primary: new Color(0x00a4d6),
		secondary: new Color('rgba(223,224,226,0.2)'),
		error: new Color(0xcd5c5c),
	},
	text: {
		body: {
			lineHeight: '1.25rem',
		},
	},
}
