import { createGlobalStyle } from 'styled-components'

/* This is a fix for https://github.com/styled-components/styled-components/issues/1593
  if not used when whenever menuItems are hovered styled components request
    the fonts again from the server, causing font swap to kick causing
    the menuItem's text to jump
*/
import './global-fonts-fix.css'

const GlobalFonts = createGlobalStyle``

export default GlobalFonts
