import React, { FC, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import lightTheme from '../../themes/lightTheme'
import { MediaQueries } from '../../tokens/mediaQueries'

export interface PageWrapperProps {
	backgroundColor?: string
}

export const PageWrapper = styled.div<PageWrapperProps>`
	background-color: ${(props) => props.backgroundColor};
`

export interface ContainerStyledProps {
	hasOffset?: boolean
}

export const ContainerStyled = styled.div<ContainerStyledProps>(
	({ hasOffset = false }) => css`
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;

		padding: ${hasOffset ? '0 20px' : null};

		${MediaQueries.tablet} {
			padding: ${hasOffset ? '0 65px' : null};
		}

		${MediaQueries.desktop} {
			padding: ${hasOffset ? '0 130px' : null};
		}
	`,
)

export interface ContainerProps extends ContainerStyledProps {
	className?: string
	withPageWrapper?: boolean
	pageWrapperBgColor?: string
	onClick?: () => void
}

const Container: FC<PropsWithChildren<ContainerProps>> = ({
	hasOffset = false,
	withPageWrapper = false,
	pageWrapperBgColor = lightTheme.accents.lightGray20,
	className = '',
	onClick,
	children,
}) => {
	return (
		<>
			{withPageWrapper ? (
				<PageWrapper backgroundColor={pageWrapperBgColor} onClick={onClick}>
					<ContainerStyled
						hasOffset={hasOffset}
						children={children}
						className={className}
					/>
				</PageWrapper>
			) : (
				<ContainerStyled
					hasOffset={hasOffset}
					children={children}
					className={className}
					onClick={onClick}
				/>
			)}
		</>
	)
}

export default styled(Container)``
