import { Property } from 'csstype'
import { createGlobalStyle } from 'styled-components'

const GlobalText = createGlobalStyle`
  html {
    font-size: 62.5%;
    font-family: ${({ theme }) => `${theme.text.body.fontFamily}, sans-serif`};
    color: ${({ theme }) => theme.text.body.color as Property.Color};
  }
`

export default GlobalText
