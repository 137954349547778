import { useState } from 'react'
import useBrowserStorage from './useBrowserStorage'
import { ConsentCategory } from '../../common/consent/consent'

export const useDatoLocale = (
	allLocales: string[],
	localeMapSlug: { [locale: string]: string },
) => {
	const [storedLocale, setStoredLocale] = useBrowserStorage(
		'locale',
		'en', // @TODO wrap default value with all other cases (eg url containing the locale)
		{
			consentCategory: ConsentCategory.functionality,
			expires: 365, // 1 year
		},
	)

	const [marketSlug, setMarketSlug] = useState('en_INT')

	const [locale, setLocale] = useState(storedLocale)
	const [internalLocaleChange, setInternalLocaleChange] =
		useState<boolean>(false)

	if (localeMapSlug && marketSlug !== localeMapSlug[locale]) {
		setMarketSlug(localeMapSlug[locale])
	}

	const changeLocale = (newLocale: string, internal: boolean = false) => {
		const isValidLocale = allLocales.includes(newLocale)

		if (isValidLocale) {
			setLocale(newLocale)
			setInternalLocaleChange(internal)
			setMarketSlug(localeMapSlug[newLocale])
			setStoredLocale(newLocale)
		}
	}

	return {
		locale,
		changeLocale,
		marketSlug,
		internalLocaleChange,
		setInternalLocaleChange,
	}
}
