import styled, { css } from 'styled-components'
import React, { FC } from 'react'
import { MediaQueries } from '../../tokens/mediaQueries'

export interface SpaceBoxProps {
	height: [number, number]
	width: [number, number]
	className: string
}

export const SpaceBox = styled.div<SpaceBoxProps>(
	({ height, width }) => css`
		flex-shrink: 0;
		height: ${height[0]}px;
		width: ${width[0]}px;
		${height[0] === 0 && width[0] === 0 && 'display: none;'}

		${MediaQueries.desktop} {
			display: block;
			height: ${height[1]}px;
			width: ${width[1]}px;
			${height[1] === 0 && width[1] === 0 && 'display: none;'}
		}
	`,
)

export interface SpacingProps {
	height?: [number, number] | number
	width?: [number, number] | number
	className?: string
}

const Spacing: FC<SpacingProps> = ({
	height = 0,
	width = 0,
	className = '',
}) => {
	const spaceHeight = Array.isArray(height)
		? height
		: ([height, height] as [number, number])
	const spaceWidth = Array.isArray(width)
		? width
		: ([width, width] as [number, number])

	return (
		<SpaceBox height={spaceHeight} width={spaceWidth} className={className} />
	)
}

export default Spacing
