import { navigate, PageProps } from 'gatsby'
import { Dispatch, SetStateAction, useEffect } from 'react'

const redirect = (internalUrl: string) => {
	if (navigate) {
		navigate(internalUrl)
	}
}

export const useHandleRedirects = (
	marketSlug: string,
	changeLocale: (locale: string, internal?: boolean) => void,
	internalLocaleChange: boolean,
	setInternalLocaleChange: Dispatch<SetStateAction<boolean>>,
	location: Partial<PageProps['location']> & { pathname: string },
	slugMapLocale?: { [slug: string]: string },
) => {
	useEffect(() => {
		if (!slugMapLocale) return
		const allMarketSlugs = Object.keys(slugMapLocale)
		const currentUrl = location.pathname
		let isRootPage = false
		let urlMarketSlug = currentUrl.split('/')[1]
		let pageSlug = ''

		if (!allMarketSlugs.includes(urlMarketSlug)) {
			isRootPage = true
			urlMarketSlug = 'en_INT'
			pageSlug = currentUrl
		} else {
			pageSlug = currentUrl.replace(`/${urlMarketSlug}`, '')
		}

		if (isRootPage) {
			if (marketSlug !== 'en_INT') {
				/* user visited root url but his preference
              is not the international market */
				redirect(`/${marketSlug}${pageSlug}`)
			}
		} else {
			if (marketSlug !== urlMarketSlug) {
				if (internalLocaleChange) {
					// user changed market from popup
					redirect(`/${marketSlug}${pageSlug}`)
					setInternalLocaleChange(false)
				} else {
					/* user visited a new market through the url, in
          this case this market is his new preference */
					changeLocale(slugMapLocale[urlMarketSlug])
				}
			}
		}
	}, [location, marketSlug])
}
