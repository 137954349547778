export enum ConsentCategory {
	ad = 'ad_storage',
	analytics = 'analytics_storage',
	functionality = 'functionality_storage',
	personalization = 'personalization_storage',
	security = 'security_storage',
}

export enum ConsentValue {
	GRANTED = 'granted',
	DENIED = 'denied',
}

export const DEFAULT_CONSENT: Record<ConsentCategory, ConsentValue> = {
	[ConsentCategory.ad]: ConsentValue.DENIED,
	[ConsentCategory.analytics]: ConsentValue.DENIED,
	[ConsentCategory.functionality]: ConsentValue.DENIED,
	[ConsentCategory.personalization]: ConsentValue.DENIED,
	[ConsentCategory.security]: ConsentValue.DENIED,
}

export const CONSENT_STORAGE_KEY = 'msc:googleConsentMode'
