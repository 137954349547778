import React, { PropsWithChildren, createContext, useState } from 'react'
import {
	CONSENT_STORAGE_KEY,
	ConsentCategory,
	ConsentValue,
	DEFAULT_CONSENT,
} from '../../common/consent/consent'
import useLocalStorage from '../hooks/useLocalStorage'

interface ConsentContext {
	stored: Record<ConsentCategory, ConsentValue>
	setStored: (value: Record<ConsentCategory, ConsentValue>) => void
	visible: boolean
	setVisible: (value: boolean) => void
}

export const ConsentContext = createContext<ConsentContext>({
	stored: DEFAULT_CONSENT,
	setStored: () => {},
	visible: false,
	setVisible: () => {},
})

export const ConsentProvider = ({ children }: PropsWithChildren) => {
	const [stored, setStored] = useLocalStorage<
		Record<ConsentCategory, ConsentValue> | undefined
	>(CONSENT_STORAGE_KEY, undefined)

	const [visible, setVisible] = useState(
		!stored && typeof window !== 'undefined',
	)

	return (
		<ConsentContext.Provider
			value={{
				stored: stored ?? DEFAULT_CONSENT,
				setStored,
				visible,
				setVisible,
			}}
		>
			{children}
		</ConsentContext.Provider>
	)
}
