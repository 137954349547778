import { useState } from 'react'
import Cookies from 'js-cookie'
import {
	CONSENT_STORAGE_KEY,
	ConsentCategory,
	ConsentValue,
} from '../../common/consent/consent'
import useLocalStorage from './useLocalStorage'

interface Options {
	consentCategory: ConsentCategory
	expires?: number
}

const useBrowserStorage = (
	key: string,
	defaultValue: string,
	{ consentCategory, ...options }: Options,
) => {
	const [value, setValue] = useState(() => Cookies.get(key) ?? defaultValue)

	const [storedConsent] = useLocalStorage<
		Record<ConsentCategory, ConsentValue> | undefined
	>(CONSENT_STORAGE_KEY, undefined)

	const handleSetValue = (newValue: string) => {
		if (storedConsent?.[consentCategory] === ConsentValue.GRANTED) {
			Cookies.set(key, newValue, {
				...options,
				// Always use the domain configured in the environment to avoid
				// issues with removing cookies when consent is denied
				domain: process.env.GATSBY_COOKIE_DOMAIN,
			})
			setValue(newValue)
		}
	}

	return [value, handleSetValue] as const
}

export default useBrowserStorage
