import React from 'react'
import { DictionaryEntryProps } from '../../common/types/dictionary'

export interface DictionaryContextInterface {
	entries: DictionaryEntryProps[]
	get: (key: string, defaultValue?: string) => string
}

/**
 * Helper method to get dictionary entry by key
 * @param dictionary
 * @param key
 * @param defaultValue
 */
export const getDictionaryEntryByKey = (
	dictionary: DictionaryEntryProps[],
	key: string,
	defaultValue?: string,
): string => {
	// Double check for the dictionary
	if (!dictionary) {
		return defaultValue || `[${key}]`
	}
	const result = dictionary.find(
		(entry: DictionaryEntryProps) => entry.key === key,
	)

	if (result && result.value !== null && result.value !== '') {
		// Return the result if it's available
		return result.value
	}
	if (defaultValue) {
		// Else return the default value
		return defaultValue
	}
	// Else return an indicator of missing dictionary item
	return `[${key}]`
}

const DictionaryContext = React.createContext<DictionaryContextInterface>({
	entries: [],
	get: (key, defaultValue) => {
		if (defaultValue) {
			return defaultValue
		}

		return key
	},
})

export const DictionaryProvider = DictionaryContext.Provider
export const DictionaryConsumer = DictionaryContext.Consumer

export default DictionaryContext
